import './App.scss';
import RoutesTree from './Components/RoutesTree';

export default function App() {
  return (
      <div  className="App">
        <RoutesTree/>
      </div> 

    
  );
}
